<div fxLayout="column" fxFlex>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <app-submit-button label="" (onClick)="confirm()" [isLoading]="isLoading" tabindex="3" cdkFocusInitial>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div>Proceed</div>
      </div>
    </app-submit-button>
  </div>
</div>