<ul class="collapsable-menu" *ngFor="let menuItem of navItems">

  <li class="menu-group">
    <div class="menu-toggle" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" (click)="menuItem.expand = !menuItem.expand">
      <mat-icon class="material-icons-outlined">{{ menuItem.icon }}</mat-icon>
      <span fxFlex>{{ menuItem.title }}</span>
      <span class="material-icons-outlined expander" *ngIf="menuItem.expand">
        expand_more
      </span>
      <span class="material-icons-outlined expander" *ngIf="!menuItem.expand">
        expand_less
      </span>
    </div>

    <ul class="menu-items" [ngClass]="{ expand: menuItem.expand, contract: !menuItem.expand }">
      <li *ngFor="let subMenuItem of menuItem.subMenu">
        <a [routerLink]="subMenuItem.path" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <mat-icon class="material-icons-outlined">
            {{ subMenuItem.icon}}
          </mat-icon>
          <span fxFlex>{{ subMenuItem.title }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>