<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

  <div class="topbar-container" fxLayout="row" fxLayoutAlign="space-between center">
    <app-logo></app-logo>
    <button mat-icon-button (click)="toggleSidneNav()" *ngIf="isMobile">
      <mat-icon>menu_open</mat-icon>
    </button>
  </div>

  <app-collapsable-menu [navItems]="navItems" (click)="toggleSidneNav()"></app-collapsable-menu>

</div>