<mat-sidenav-container  *ngIf="isMobile$ | async as isMobile"  (backdropClick)="sidenav.close()">
  <mat-sidenav #sidenav [opened]="layout.opened" [mode]="layout.mode" disableClose>
    <app-side-nav
      (toggleSideNav)="isMobile.mobile && sidenav.toggle()"
      [isMobile]="isMobile.mobile"
      fxFlex>
    </app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column" fxLayoutAlign="start center" fxFlex [ngClass]="{'is-mobile': isMobile.mobile}">
    <app-nav-bar
      [isMobile]="isMobile.mobile"
      (toggleSideMenu)="sidenav.toggle()"
      (logout)="doLogout()"
      ></app-nav-bar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


