import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { navItems } from './side-nav.config';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Output() toggleSideNav = new EventEmitter();
  @Input() isMobile: boolean;
  navItems = navItems;

  constructor() { }

  ngOnInit(): void {
  }

  toggleSidneNav() {
    this.toggleSideNav.emit('toggle');
  }

}
