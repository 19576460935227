<!-- <label class="form-label">{{ phone }}<span (click)="addPhone()" *ngIf="phoneCtrls.length-1 === indexOfelement && isEditMode()">+</span></label>
<div class="input-group">

  <select class="form-select max-width-130" formControlName="{{phone}}Type"  [ngClass]="{'is-invalid': getControl(phone+'Type').touched && getControl(phone+'Type').invalid}">
    <option value="">Type</option>
    <option *ngFor="let phoneType of phoneTypes"  [value]="phoneType">
      {{phoneType}}
    </option>
  </select>

  <input type="text" [formControlName]="phone" class="form-control"
    [ngClass]="{'is-invalid': getControl(phone).touched && getControl(phone).invalid}">

  <div class="invalid-feedback">
    A valid phone is required
  </div>
</div> -->
<div fxLayout="row" fxLayoutAlign="start center" [formGroup]="form">

  <mat-form-field appearance="outline" fxFlex="30">
    <mat-label>Type</mat-label>
    <mat-select  formControlName="{{name}}Type" >
      <mat-option>None</mat-option>
      <mat-option *ngFor="let phoneType of phoneTypes" [value]="phoneType">{{phoneType}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="70">
    <mat-label>{{ name }}</mat-label>
    <input matInput [formControlName]="name">
    <mat-error>A number is required</mat-error>
  </mat-form-field>
</div>
