import { Component, Input, OnInit } from '@angular/core';

export interface NavItem {
  path: string;
  title: string;
  icon: string;
  class: string;
  isExternalLink: boolean;
  subMenu? : NavItem[];
  expand?: boolean;
}


@Component({
  selector: 'app-collapsable-menu',
  templateUrl: './collapsable-menu.component.html',
  styleUrls: ['./collapsable-menu.component.scss']
})
export class CollapsableMenuComponent implements OnInit {
  @Input() navItems: NavItem[];
  expand = true;
  constructor() { }


  ngOnInit(): void {
    console.log(this.navItems)
  }

}
