import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { Contact } from '../../models/contact.model';
import { ContactsService } from '../../service/contacts.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsResolver implements Resolve<Contact[]> {
  constructor(
    private contactService: ContactsService,
    private appState: AppStateService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Contact[]> {
    if (!this.appState.contacts.length) {
      return this.contactService.getContacts();
    } else {
      return of(this.appState.contacts);
    }
  }
}
