<div fxLayout="row" fxLayoutAlign="start center" [formGroup]="form">

  <mat-form-field appearance="outline" fxFlex="30">
    <mat-label>Type</mat-label>
    <mat-select  formControlName="{{name}}Type" >
      <mat-option>None</mat-option>
      <mat-option *ngFor="let emailType of emailTypes" [value]="emailType">{{emailType}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="70">
    <mat-label>{{ name }}</mat-label>
    <input type="email" matInput [formControlName]="name">
    <mat-error>A valid email is required</mat-error>
  </mat-form-field>

</div>
