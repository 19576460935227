import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Input() isMobile: boolean;
  @Output() toggleSideMenu = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  doToggleSideMenu() {
    this.toggleSideMenu.emit(true);
  }

  doLogout() {
    this.logout.emit(true);
  }

}
