import { Component, OnInit } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { map, Subject, takeUntil, tap } from 'rxjs';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

export interface Layout {
  openSideNav: boolean;
  opened: boolean;
  mode: MatDrawerMode;
  fixedViewPort: boolean;
  fixedTopGap: number;
}

const defaultLayout: Layout = {
  openSideNav: true,
  opened: true,
  mode: 'side',
  fixedViewPort: true,
  fixedTopGap: 64,
};

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  layout: Layout = defaultLayout;
  isMobile$ = this.layoutService.isMobile$
    .pipe(map(val => ({ mobile: !!val })));
  onDestroy = new Subject();

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.layoutService.breakpoint$
      .pipe(
        takeUntil(this.onDestroy),
        tap(deviceMap => {
          if (deviceMap['is-mobile'] || deviceMap['is-tablet-portrait']) {
            this.layout = {
              ...this.layout,
              mode: 'over',
              opened: false
            };
          } else {
            this.layout = {
              ...this.layout,
              mode: 'side',
              opened: true
            }
          }
        })
      ).subscribe();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  doLogout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }
}
