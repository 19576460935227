import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';
import { ContactsResolver } from './modules/contacts/resolvers/contacts/contacts.resolver';
import { LayoutComponent } from './shared/components/layout/layout.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [
      AuthGuard,
    ],
    resolve: {
      contacts: ContactsResolver,
    },
    children: [
      {
        path: 'contacts',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
    },
    { path: '**', redirectTo: 'contacts' }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
