import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { Dictionary } from 'src/app/shared/models/global';
import { Contact } from '../models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }


  getContacts(): Observable<Contact[]> {

    const statement = `
      query getContacts {
        getContacts {
          company
          email {
            type
            value
          }
          id
          firstName
          jobTitle
          lastName
          linkedIn
          notes
          phone {
            type
            value
          }
          address {
            address
            postcode
            state
            city
            country
          }
          picture
          securityClearance
        }
      }
    `;
    return this.apiService
      .graphql<Contact[]>(statement, {}, 'getContacts')
      .pipe(
        tap(contacts => this.appState.contacts = contacts)
      )
  }

  getContact(id: string): Observable<Contact> {
    const contact = this.appState.contacts.find(contact => contact.id === id);
    if (contact) {
      return of(contact);
    } else {
      const statement = `
        query getContact($id: ID!) {
          getContact(id: $id) {
            company
            email {
              type
              value
            }
            id
            firstName
            jobTitle
            lastName
            linkedIn
            notes
            phone {
              type
              value
            }
            picture
            address {
              address
              postcode
              state
              city
              country
            }
            securityClearance
          }
        }
      `;
      return this.apiService
        .graphql<Contact>(statement, { id }, 'getContact')
    }
  }

  storeContact(contact: Contact) {
    // remove empty values
    (Object.keys(contact) as [keyof Contact]).forEach(key => contact[key] === '' ? delete contact[key] : null);
    const input: Dictionary<any> = {
      ...contact,
    };

    const statement = `mutation storeContact($input: ContactInput!) {
      storeContact(input: $input) {
        company
        email {
          value
          type
        }
        id
        firstName
        jobTitle
        lastName
        linkedIn
        notes
        phone {
          value
          type
        }
        picture
        address {
          address
          postcode
          state
          city
          country
        }
        securityClearance
      }
    }`;
    return this.apiService
      .graphql<Contact>(statement, { input }, 'storeContact')
      .pipe(
        tap(updated => {
          this.appState.contacts = this.appState.contacts.map(contact => {
            if (contact.id === updated.id ) {
              contact = { ...updated };
            }
            return contact;
          })
        })
      )
  }

  deleteContact(id: string) {
    const statement = `mutation deleteContact($id: String!) {
      deleteContact(id: $id)
    }`;
    return this.apiService
      .graphql<Contact>(statement, { id }, 'deleteContact')
      .pipe(
        tap(updated => {
          this.appState.contacts = this.appState.contacts.filter(contact => contact.id !== id)
        })
      )
  }
}
