
/*
******* NOTICE **********
This is an auto generated file. Please DO NOT modify !!
if you want to add any new config, please have a look at aws-init.ts
*************************
*/

export interface AwsConfig {
  aws_project_region: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: {};
  aws_user_files_s3_bucket_region: string;
  acm_arn?: string;
};

 export const awsConfig: AwsConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_appsync_graphqlEndpoint: 'https://2ykcpjponfhmvh53ohtlikrvmu.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: 'ap-southeast-2:cd4fbd76-9437-404b-85c9-62d82da5a39b',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_jNGUfZRo5',
  aws_user_pools_web_client_id: '6sh90t3ntql6185f90m9m5q5og',
  oauth: {},
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  acm_arn: 'arn:aws:acm:us-east-1:423325363422:certificate/fa0eee21-53f1-4ca4-9833-295baa499c96'
};
