import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.Handset,
    Breakpoints.Tablet,
    Breakpoints.Web,
  ]).pipe(
    map(data => {
      const deviceClass = {
        'is-mobile': this.breakpointObserver.isMatched(Breakpoints.Handset),
        'is-mobile-landscape': this.breakpointObserver.isMatched(Breakpoints.HandsetLandscape),
        'is-mobile-portrait': this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait),
        'is-tablet': this.breakpointObserver.isMatched(Breakpoints.Tablet),
        'is-tablet-portrait': this.breakpointObserver.isMatched(Breakpoints.TabletPortrait),
        'is-tablet-landscape': this.breakpointObserver.isMatched(Breakpoints.TabletLandscape),
        'is-desktop': this.breakpointObserver.isMatched(Breakpoints.Web)
      }
      const bodyTag = document.body;
      if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
        bodyTag.classList.add('is-mobile');
      } else {
        bodyTag.classList.remove('is-mobile');
      }
      return deviceClass;
    })
  );

  isMobile$ = this.breakpointObserver.observe([
    Breakpoints.Handset,
    Breakpoints.Tablet,
  ]).pipe(
    map(data => {
      const bodyTag = document.body;
      if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
        bodyTag.classList.add('is-mobile');
      } else {
        bodyTag.classList.remove('is-mobile');
      }
      return this.breakpointObserver.isMatched(Breakpoints.Handset);
    })
  );

  /**
   * Navbar title state storages. Using the subject, we can push a new title from different locations into the mobile nav bar
   */
  private _mobileNavBarTitle = new BehaviorSubject<string>('');
  mobileNavBarTitle$ = this._mobileNavBarTitle.asObservable();

  set mobileNavBarTitle(title: string) {
    this._mobileNavBarTitle.next(title);
  }
  get mobileNavBarTitle() {
    return this._mobileNavBarTitle.value;
  }

  constructor(
    public breakpointObserver: BreakpointObserver,
  ) {
    const bodyTag = document.body;
    bodyTag.classList.add('loaded');
  }
}
