<nav class="nav-bar" fxFlex="100" fxLayout="row" fxLayoutAlign="centers center" [ngClass]="{ 'is-mobile': isMobile }">
  <button mat-icon-button (click)="doToggleSideMenu()" *ngIf="isMobile">
    <mat-icon >menu</mat-icon>
  </button>
  <div fxFlex class="search">
    <mat-form-field appearance="outline">
      <input matInput placeholder="Type to search">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="divider"></div>
  <div class="avatar">
    <button color="primary" [matMenuTriggerFor]="menu" mat-mini-fab aria-label="Example icon-button with a heart icon">
      <mat-icon>person</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="doLogout()">Logout</button>
    </mat-menu>

  </div>
</nav>
