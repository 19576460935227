import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  emailTypes = ['HOME', 'WORK', 'OTHER'];

  @Input() name: string;
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
