import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Auth, API } from 'aws-amplify';
import { awsConfig } from './aws-exports';
import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

API.configure(awsConfig);
Auth.configure({
  region: awsConfig.aws_project_region,
  userPoolId: awsConfig.aws_user_pools_id,
  userPoolWebClientId: awsConfig.aws_user_pools_web_client_id,
  identityPoolId: awsConfig.aws_cognito_identity_pool_id,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
