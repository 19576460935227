import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Contact } from 'src/app/modules/contacts/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private _contacts = new BehaviorSubject<Contact[]>([]);
  contacts$ = this._contacts.asObservable();

  set contacts(products: Contact[]) {
    this._contacts.next(products)
  }

  get contacts() {
    return this._contacts.value;
  }

  constructor() { }

  /**
 * On logout we need to clear the state in case browsers are not refresh, flushing the cache
 */
  clearState() {
    this._contacts.next([]);
    this._contacts.complete();
  }

  /**
   * Reinitialise the Cache items
   */
  initState() {
    this.clearState();

    this._contacts = new BehaviorSubject<Contact[]>([]);
    this.contacts$ = this._contacts.asObservable();
  }

}
