import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession, CognitoIdToken } from 'amazon-cognito-identity-js';
import { User } from '../models/auth.model';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';

export interface AuthState {
  isLoggedIn: boolean;
  id?: string;
}

export interface IdTokenPayload {
  aud: string;
  auth_time: number;
  'cognito:username': string;
  'custom:verification': string;
  'custom:newEmail': string;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
  token_use: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  initialAuthState: AuthState = {
    isLoggedIn: false,
  };

  private readonly _authState = new BehaviorSubject<AuthState>(this.initialAuthState);
  readonly authState$ = this._authState.asObservable();

  set authState(authState: AuthState) {
    this._authState.next(authState);
  }

  get currentSession() {
    return from(Auth.currentSession()).pipe(
      catchError(error => {
        return of(false);
      })
    );
  }

  constructor(
    private appState: AppStateService,
  ) { }

  login(user: User): Observable<{ status: string }> {
    this.appState.initState();
    return from<Promise<CognitoUser>>(Auth.signIn(user.username, user.password))
      .pipe(
        map(val => ({ status: 'success' })),
        catchError(error => {
          return of({
            status: 'error'
          });
        })
      );
  }

  logout(authState = this.initialAuthState) {
    Auth.signOut();
    this.appState.clearState();
    this._authState.next(authState);
    return of(true);
  }

  initiateResetPassword(username: string) {
    return from<Promise<CognitoUser>>(Auth.forgotPassword(username))
      .pipe(
        map(val => ({ status: 'success' })),
        catchError(error => {
          return of({
            status: 'error'
          });
        })
      );
  }

  isLoggedIn(): Observable<AuthState | null> {

    return this.currentSession
      .pipe(
        map((val: CognitoUserSession | boolean) => {
          if (typeof (val) !== 'boolean') {
            const idTokenPayload = val.getIdToken().payload as IdTokenPayload;
            const authState = {
              isLoggedIn: true,
              id: idTokenPayload['cognito:username'],
            }
            // push the authstate because someone might have refreshed the browser and then the authstate
            // would be in its initial state
            this._authState.next(authState);
            return authState;
          } else {
            return this.initialAuthState;
          }
        })
      );
  }
}
